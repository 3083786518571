import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { FormBuilder, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-account-profile-information',
  templateUrl: './account-profile-information.component.html',
  styleUrls: ['./account-profile-information.component.scss']
})
export class AccountProfileInformationComponent implements OnInit {

  @Input() userInfo: any;

  @Output()
  public onCancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public icon = iconLib;
  public sampleSignature = './assets/sample/signature.png';
  public sampleAvatar = '/assets/sample/avatar.png';
  public form: FormGroup = this.formBuilder.group({
    name: [''],
    phone: [''],
    contactEmail: [''],
    reservationUpdatesEmail: [false],
    reservationUpdatesSms: [false],
  });

  public sampleLanguages = [{ id: 'abc', name: 'English' }, {id: 'efg', name: 'Spanish'}];

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.form.patchValue({
      name: this.userInfo?.name ?? '',
      phone: this.userInfo?.phones?.[0]?.number ?? '',
      contactEmail: this.userInfo?.contactEmail ?? '',
      reservationUpdatesEmail: this.userInfo?.reservationUpdatesEmail ?? false,
      reservationUpdatesSms: this.userInfo?.reservationUpdatesSms ?? false,
    })
  }

  public setLanguage(lgs: string): void {
    console.log(lgs);
    // const l = [];
    // l.push(lgs);
    // console.log(l);
  }

  public uploadPhoto(event: Event): void {
    console.log(event);
  }

  public cancel(): void {
    this.onCancel.emit();
  }
}

