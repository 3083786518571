import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
})
export class AccountProfileComponent implements OnInit {
  taskDetailsOpened: boolean = false;

  get isMobile() {
    return this.deviceService.isMobile();
  }

  constructor(
    private deviceService: DeviceDetectorService,
) {
  }

  ngOnInit() {}

  onSidebarOpen(event: boolean) {
    this.taskDetailsOpened = event;
  }
}
