import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MiniCalendarComponent } from "@app/core/components/google-suite/mini-calendar/mini-calendar.component";
import { MiniInboxComponent } from "@app/core/components/google-suite/mini-inbox/mini-inbox.component";
import { AccountProfileComponent } from "@app/pages/account-profile/account-profile.component";
import { EDIT_ACCOUNT_COMPONENTS } from "@app/pages/account-profile/components/profile";
import { MaterialModule } from "@app/shared/material/material.module";
import { SharedModule } from "@app/shared/shared.module";
import { OAuthModule } from "angular-oauth2-oidc";
import { InlineSVGModule } from "ng-inline-svg-2";
import { AccountProfileRoutingModule } from "./account-profile-routing.module";
import { AccountInfoComponent } from "./components/account-info/account-info.component";
import { LoginSocmedComponent } from "./components/login-socmed/login-socmed.component";
import { AccountEditComponent } from "./pages/account-edit/account-edit.component";
import { AccountLoginMainComponent } from "./pages/account-login-main/account-login-main.component";
import { AccountMainComponent } from "./pages/account-main/account-main.component";
import { AccountComponent } from "./pages/account/account.component";

@NgModule({
  declarations: [
    [...EDIT_ACCOUNT_COMPONENTS],
    AccountProfileComponent,
    AccountComponent,
    AccountInfoComponent,
    AccountEditComponent,
    AccountMainComponent,
    AccountLoginMainComponent,
    MiniCalendarComponent,
    MiniInboxComponent,
    LoginSocmedComponent,
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    MaterialModule,
    SharedModule,
    AccountProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
  ],
})
export class AccountProfileModule {}
