import { Component, OnInit } from '@angular/core';
import { GoogleAuthService } from '@app/shared/services/google-auth.service'

@Component({
  selector: 'app-mini-calendar',
  templateUrl: './mini-calendar.component.html',
  styleUrls: ['./mini-calendar.component.scss']
})
export class MiniCalendarComponent implements OnInit {
  events: any[] = [];
  haveToken: boolean = false;

  constructor(
    private googleAuthService: GoogleAuthService,
  ) {
    this.googleAuthService.haveGoogleToken().subscribe((data) => {
      this.haveToken = data;
    });
  }

  ngOnInit(): void {
    if (this.haveToken) {
      this.googleAuthService.getGoogleCalendarEvents().subscribe((data) => {
        this.events = data;
      });
    }
  }
}
