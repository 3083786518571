import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent {

  @Input()
  public label: string = '';

  @Input()
  public info: string = '';

  @Output()
  public onEdit: EventEmitter<any> = new EventEmitter<any>();

}
