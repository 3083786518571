import { Component, inject } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AuthStore } from '@app/core/auth';
import { dialogConfig } from "@app/core/const/dialog.const";
import { iconLib } from "@app/core/const/roam-icon";
import { AccountEditComponent } from "@app/pages/account-profile/pages/account-edit/account-edit.component";
import { accountMenu } from "@app/pages/account-profile/shared/const/account-edit-menu.const";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  public user = inject(AuthStore).getAuthUser()!;
  public userInfo = this.user;
  public icon = iconLib;
  public accountMenu = accountMenu;
  public sampleAvatar = '/assets/sample/avatar.png';

  constructor(
    private dialog: MatDialog,
  ) { }

  public editProfile(path: string = '', tab: string = ''): void {
    this.dialog.open(AccountEditComponent, {
      ...dialogConfig.formEdit,
      data: {
        path: accountMenu.PROFILE,
        tab: tab
      }
    })
  }
}
