import { Component, EventEmitter, Output } from "@angular/core";
import { UserService } from '@app/shared/services/user.service'
import { GoogleAuthService } from '@app/shared/services/google-auth.service'

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss']
})
export class AccountLoginComponent {

  @Output()
  public onCancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public calendars: any[] = [];
  public events: any[] = [];
  public folders: any[] = [];
  public messages: any[] = [];

  public googleEvents: any[] = [];
  public googleMessages: any[] = [];

  constructor(
    private userService: UserService,
    private googleAuthService: GoogleAuthService,
  ) {}

  public cancel(): void {
    this.onCancel.emit();
  }

  // Google

  public loadGoogleEvents(): void {
    this.googleAuthService.getGoogleCalendarEvents().subscribe((res) => {
      this.googleEvents = res.map((event: any) => {
        return {
          id: event.id,
          date: new Date(event.start.date),
          summary: event.summary,
        };
      });
    });
  }

  public loadGoogleMessages(): void {
    this.googleAuthService.getGoogleMailMessages().subscribe((res) => {
      this.googleMessages = res.map((message: any) => {
        return {
          id: message.id,
          date: new Date(message.internalDate / 1000),
          from: message.payload.headers.find((header: any) => header.name === 'From').value,
          subject: message.payload.headers.find((header: any) => header.name === 'Subject').value,
        };
      });
    });
  }

  // MS

  public loadMicrosoftCalendars(): void {
    this.userService.getMicrosoftCalendars().subscribe((res) => {
      this.calendars = res
    });
  }

  public loadMicrosoftEvents(): void {
    this.userService.getMicrosoftEvents().subscribe((res) => {
      this.events = res;
    });
  }

  public loadMicrosoftMailFolders(): void {
    this.userService.getMicrosoftMailFolders().subscribe((res) => {
      this.folders = res;
    });
  }

  public loadMicrosoftMessages(): void {
    this.userService.getMicrosoftMessages().subscribe((res) => {
      this.messages = res;
    });
  }
}
