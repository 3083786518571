<div class="d-flex gap-16">
  <div class="card" *ngFor="let account of socmeds" (click)="connectAccount(account.key)">
    <div>
      <div class="text-label">
        {{ account.name }}
      </div>
      <span class="tc-dust font-14px fw-400">
          {{ isConnected(account.key) ? 'Connected' : 'Not Connected' }}
      </span>
    </div>
    <img [src]="isConnected(account.key) ? account.icons.connected : account.icons.disconnected" [alt]="account.name">
  </div>
</div>
