import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AuthStore } from "@app/core/auth";

@Component({
  selector: "app-account-profile-edit",
  templateUrl: "./account-profile-edit.component.html",
  styleUrls: ["./account-profile-edit.component.scss"],
})
export class AccountProfileEditComponent implements OnInit {
  public userInfo = inject(AuthStore).getAuthUser();

  @Input()
  public tab: string = "";

  @Output()
  public onCancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public profileTabMenus = ["Profile", "Addresses"];
  public selectedMenu: string = this.profileTabMenus[0];

  ngOnInit(): void {
    this.setActiveTab();
  }

  private setActiveTab(): void {
    this.selectedMenu = this.tab || this.profileTabMenus[0];
  }
}
