export const profileTabs: any[] = [
  {
    id: "./",
    name: "Profile",
    route: "/profile",
  },
  {
    id: "login",
    name: "Login & Password",
    route: "/profile/login",
  },
  {
    id: "payment",
    name: "Payment Method",
    route: "/profile/payment",
  },
  {
    id: "vehicle",
    name: "Vehicle Information",
    route: "/profile/vehicle",
  },
  {
    id: "property",
    name: "Property Detail",
    route: "/profile/property",
  },
];
