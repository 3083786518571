import { Component } from '@angular/core';

@Component({
  selector: 'app-account-profile-address',
  templateUrl: './account-profile-address.component.html',
  styleUrls: ['./account-profile-address.component.scss']
})
export class AccountProfileAddressComponent {

}
