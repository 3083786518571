import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GoogleAuthService {
  private apiUrl = environment.apiUrl

  constructor(private httpClient: HttpClient) {}

  // Google Auth Calls

  public handleGoogleTokens(tokens: any): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/handle-google-tokens`, {tokens: tokens}, { withCredentials: true });
  };
  public haveGoogleToken(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/have-google-token`, { withCredentials: true });
  }
  public refreshGoogleToken(token: string, expires: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/refresh-google-token`, {token: token, expires: expires}, { withCredentials: true });
  }
  public revokeGoogleToken(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/revoke-google-token`, { withCredentials: true });
  }

  // Google Calendar Calls

  public getGoogleCalendarEvents(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/google/calendar-events`, { withCredentials: true });
  }

  // Google Mail Messages

  public getGoogleMailMessages(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/google/mail-messages`, { withCredentials: true });
  }
}
