import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccountLoginMainComponent } from "@app/pages/account-profile/pages/account-login-main/account-login-main.component";
import { AccountMainComponent } from "@app/pages/account-profile/pages/account-main/account-main.component";
import { AccountComponent } from "@app/pages/account-profile/pages/account/account.component";
import { AccountProfileComponent } from "./account-profile.component";

const routes: Routes = [
  {
    path: "",
    component: AccountProfileComponent,
    children: [
      {
        path: "",
        component: AccountMainComponent,
        children: [
          {
            path: "",
            component: AccountComponent,
          },
          {
            path: "login",
            component: AccountLoginMainComponent,
          },
          {
            path: "payment",
            component: AccountProfileComponent, // will be replace
          },
          {
            path: "vehicle",
            component: AccountProfileComponent, // will be replace
          },
          {
            path: "property",
            component: AccountProfileComponent, // will be replace
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountProfileRoutingModule {}
