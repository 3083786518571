<div class="profile-info container-dialog-set pt-16" [formGroup]="form">
  <div class="gap-24 align-center">
    <div>
      <app-image-view [width]="'80px'" [height]="'80px'"
                      [rounded]="true"
                      [image]="userInfo?.avatarUrl"
                      [placeholderImage]="'user-grey'"
      ></app-image-view>
    </div>
    <div>
      <div class="font-14px fw-400 tc-dust mb-5">
        SVG, PNG, JPG (max. 300x300px)
      </div>
      <div>
        <app-button-file [label]="'Upload Photo'"
                         [class]="'btn btn-o-primary'"
                         [icon]="icon.plusRed.src"
                         (onChange)="uploadPhoto($event)"
        ></app-button-file>
      </div>
    </div>
  </div>

  <div class="form-set mt-30">
    <app-roam-input [name]="'Full Name'" formControlName="name"></app-roam-input>
    <div class="d-flex gap-16 phone">
      <app-roam-select [placeholder]="'Country'"></app-roam-select>
      <app-roam-input [name]="'Number'" formControlName="phone"></app-roam-input>
    </div>
    <app-roam-input [name]="'Email'" formControlName="contactEmail"></app-roam-input>

    <!-- LANGUAGE -->
    <div class="mt-m-8">
      <label class="text-label">Language</label>
      <div class="mt-6">
        <app-checkbox-card *ngFor="let language of sampleLanguages"
                           [id]="language.id"
                           [name]="'language'"
                           [label]="language.name"
        ></app-checkbox-card>
      </div>
    </div>

    <!-- NOTIFICATION -->
    <div class="mt-12">
      <label class="text-label">Notification</label>
      <div>
        <mat-checkbox class="w-50" formControlName="reservationUpdatesSms">
          <span class="fw-500">SMS Notification</span>
        </mat-checkbox>
        <mat-checkbox formControlName="reservationUpdatesEmail">
          <span class="fw-500">Email Notification</span>
        </mat-checkbox>
      </div>
    </div>

    <!-- SIGNATURE -->
    <div class="mt-8">
      <label class="text-label">Signature</label>
      <div class="mt-6">
        <app-roam-input [name]="'Signature'"></app-roam-input>
        <div class="signature-area mt-m-8">
          <img [src]="sampleSignature" alt="signature">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-container">
  <span class="text-btn-underline" (click)="cancel()">
    Cancel
  </span>
  <app-roam-button [label]="'Save'"
                   [class]="'btn-primary'"
  ></app-roam-button>
</div>
