<div class="container-dialog-set">
  <div class="set-grid set-grid-2 mt-10">
    <app-roam-input [name]="'Street 1'"></app-roam-input>
    <app-roam-input [name]="'Street 1'"></app-roam-input>
    <app-roam-input [name]="'City'"></app-roam-input>
    <app-roam-input [name]="'State'"></app-roam-input>
    <app-roam-select [placeholder]="'Country'"></app-roam-select>
    <app-roam-select [placeholder]="'Zip Code'"></app-roam-select>
  </div>
</div>

<div class="footer-container">
  <span class="text-btn-underline">Cancel</span>
  <app-roam-button [label]="'Save'"
                   [class]="'btn-primary'"
  ></app-roam-button>
</div>
