import { Component, OnInit } from '@angular/core'
import { MatDialog } from "@angular/material/dialog";
import { AccountEditComponent } from "@app/pages/account-profile/pages/account-edit/account-edit.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { accountMenu } from "@app/pages/account-profile/shared/const/account-edit-menu.const";
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc'
import { Router, ActivatedRoute } from '@angular/router'
import { GoogleAuthService } from '@app/shared/services/google-auth.service'
export const googleOAuthCodeFlowConfig: AuthConfig = {
  issuer: 'https://accounts.google.com',
  redirectUri: window.location.origin + '/profile/login',
  clientId: '879107131855-mvimcssuvhdv34docstj851jbdq7ktnv.apps.googleusercontent.com',
  responseType: 'code',
  dummyClientSecret: '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',
  scope: 'openid profile email https://www.googleapis.com/auth/calendar https://mail.google.com https://www.googleapis.com/auth/tasks',
  oidc: true,
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  requestAccessToken: true,
  customQueryParams: {
    'access_type': 'offline'
  },
  // useSilentRefresh: true,
  // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
};

@Component({
  selector: 'app-account-login-main',
  templateUrl: './account-login-main.component.html',
  styleUrls: ['./account-login-main.component.scss']
})
export class AccountLoginMainComponent implements OnInit {
  public haveTokens: any

  constructor(
    private dialog: MatDialog,
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAuthService: GoogleAuthService,
) {
    this.oauthService.configure(googleOAuthCodeFlowConfig);
    this.haveTokens = {
      google: false,
      microsoft: false
    }
  }

  ngOnInit() {
    this.googleAuthService.haveGoogleToken().subscribe(result => {
      if (result.hasToken) {
        this.haveTokens.google = true
      } else {
        this.checkForToken();
      }
    }, error => {
      console.error('Error occurred while checking for tokens:', error);
    });
  }

  public checkForToken() {
    const result = this.oauthService.hasValidAccessToken();

    if (!result) {
      return
    }
    console.log('checkForToken', this.oauthService);
    const tokens = {
      accessToken: this.oauthService.getAccessToken(),
      accessTokenExpiration: this.oauthService.getAccessTokenExpiration(),
      refreshToken: this.oauthService.getRefreshToken(),
    }

    this.googleAuthService.handleGoogleTokens(tokens).subscribe(result => {
      this.haveTokens.google = result.hasToken;
    })
    this.router.navigate([], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  public editPassword(): void {
    this.dialog.open(AccountEditComponent, {
      ...dialogConfig.formEdit,
      data: {
        index: 1,
        path: accountMenu.LOGIN,
        section: 'Login & Password'
      }
    })
  }
}
