import { Component } from '@angular/core';
import { GoogleAuthService } from '@app/shared/services/google-auth.service'

@Component({
  selector: 'app-mini-inbox',
  templateUrl: './mini-inbox.component.html',
  styleUrls: ['./mini-inbox.component.scss']
})
export class MiniInboxComponent {
  emails: any[] = [];
  haveToken: boolean = false;

  constructor(
    private googleAuthService: GoogleAuthService,
  ) {
    this.googleAuthService.haveGoogleToken().subscribe((data) => {
      this.haveToken = data;
    });
  }

  ngOnInit(): void {
    if (this.haveToken) {
      this.googleAuthService.getGoogleMailMessages().subscribe((data) => {
        this.emails = data;
      });
    }
  }
}
