<div>
  <app-roam-card>
    <ng-container cardContent>
      <div class="d-flex container-edit">
        <div class="image">
          <app-image-view [width]="'200px'" [height]="'200px'" [rounded]="true"
                          [image]="userInfo.avatarUrl || ''"
                          [placeholderImage]="'user-grey'"
                          [placeholderHeight]="'100px'"
          ></app-image-view>
          <div class="edit">
            <app-button-file [label]="'Edit'"
                             [icon]="icon.camera.src"
            ></app-button-file>
          </div>
        </div>
        <div class="info">
          <h1 class="title pb-24">Your Profile</h1>
          <div>
            <app-account-info [label]="'Full Name'"
                              [info]="userInfo.name || ''"
                              (onEdit)="editProfile()"
            ></app-account-info>

            <app-account-info [label]="'Phone Number'"
                              [info]="(userInfo.phones?.[0]?.number || '') | phone"
                              (onEdit)="editProfile()"
            ></app-account-info>

            <app-account-info [label]="'Email'"
                              [info]="userInfo.contactEmail || ''"
                              (onEdit)="editProfile()"
            ></app-account-info>

            <app-account-info [label]="'Address'"
                              [info]="$any(userInfo).customers?.[0]?.units?.[0]?.address || ''"
                              (onEdit)="editProfile('', 'Addresses')"
            ></app-account-info>

            <app-account-info [label]="'Language'"
                              [info]="userInfo.language || ''"
                              (onEdit)="editProfile()"
            ></app-account-info>

            <app-account-info [label]="'Notifications'"
                              [info]="$any(userInfo).miscUpdatesEmail"
                              (onEdit)="editProfile()"
            ></app-account-info>

            <app-account-info [label]="'Electronic Signature Format'"
                              [info]="'??'"
                              (onEdit)="editProfile()"
            ></app-account-info>
          </div>
        </div>
      </div>
    </ng-container>
  </app-roam-card>
</div>
