import { IModule } from "@app/shared/interfaces";

export enum accountMenu {
  PROFILE = 'profile',
  LOGIN = 'login',
  PAYMENT = 'payment',
  VEHICLE = 'vehicle',
  PROPERTY = 'property'
}

export const accountEditMenu: IModule[] = [
  {
    id: accountMenu.PROFILE,
    name: 'Profile',
    slug: 'userround',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: accountMenu.LOGIN,
    name: 'Login & Password',
    slug: 'lock',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: accountMenu.PAYMENT,
    name: 'Payment Method',
    slug: 'creditcard',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: accountMenu.VEHICLE,
    name: 'Vehicle Information',
    slug: 'vehicle1',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  },
  {
    id: accountMenu.PROPERTY,
    name: 'Property Detail',
    slug: 'creditcard',
    code: '',
    status: 0,
    createdAt: '',
    updatedAt: '',
    order: 0
  }
]
