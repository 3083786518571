<div class="label-group">
  <div class="label-control">
    <label>
      {{ label }}
    </label>
    <div class="text">
      {{ info }}
    </div>
  </div>
  <span class="edit" (click)="onEdit.emit()">
    Edit
  </span>
</div>
