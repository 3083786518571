import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuActionService } from "@app/pages/main/shared/services/menu-action.service";
import { IIdName } from "@app/shared/interfaces/id-name.interface";
import { profileTabs } from "../../shared/const/account-profile-tab";

@Component({
  selector: "app-account-main",
  templateUrl: "./account-main.component.html",
  styleUrls: ["./account-main.component.scss"],
})
export class AccountMainComponent implements OnInit {
  public selectedChip: string;
  public accountTabs: IIdName[] = profileTabs;

  constructor(
    private menuAct: MenuActionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const routerUrl = this.router.url;
    const tab = profileTabs.find((tab) => tab.route === routerUrl);

    this.selectedChip = tab.id;
  }

  ngOnInit(): void {
    this.menuAct.menuActive = -1;
  }

  public navigateTo(path: string = ""): void {
    this.router.navigate([path], {
      relativeTo: this.route,
    });
  }
}
