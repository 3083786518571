<div class="page-content profile">
  <h4 class="font-24px mb-24">Account</h4>
  <div class="d-flex gap-10">
    <div
      class="chip c-pointer"
      *ngFor="let chip of accountTabs"
      [class.active]="selectedChip === chip.id"
      (click)="selectedChip = chip.id; navigateTo(chip.id)"
    >
      {{ chip.name }}
    </div>
  </div>
  <hr />

  <!-- ACCOUNT TEMPLATE RENDER -->
  <div class="mt-30">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- TEMPLATE RENDER -->
