<app-roam-card>
  <ng-container cardContent>
    <div>
      <h1 class="title pb-24">Login & Password</h1>
    </div>
    <div>
      <app-account-info [label]="'Password'"
                        [info]="'Last updated 3 months ago'"
                        (onEdit)="editPassword()"
      ></app-account-info>
      <div>
        <app-login-socmed [haveTokens]="haveTokens"></app-login-socmed>
      </div>
    </div>
  </ng-container>
</app-roam-card>

<app-mini-inbox></app-mini-inbox>

<br><br>

<app-mini-calendar></app-mini-calendar>
