<app-roam-layout-container-menu [title]="'Edit Account'">
  <ng-container menu>
    <app-roam-sidebar-menu [menus]="menus"
                           [index]="index"
                           (onIndexChange)="switchMenu($event)"
    ></app-roam-sidebar-menu>
  </ng-container>
  <ng-container content>
    <div class="justify-between align-center">
      <h1 class="font-24px fw-500">
        {{ menus[index].name }}
      </h1>
      <button class="btn btn-o-black pl-24 pr-24">
        Save & Exit
      </button>
    </div>

    <!-- ACCOUNT TEMPLATE RENDER -->
    <div class="mt-30">
      <ng-container [ngSwitch]="renderTemplate">

        <!-- PROFILE -->
        <ng-container *ngSwitchCase="render.PROFILE">
          <ng-container *ngTemplateOutlet="profile; context:{tab: data.tab}"></ng-container>
        </ng-container>

        <!-- LOGIN -->
        <ng-container *ngSwitchCase="render.LOGIN">
          <ng-container *ngTemplateOutlet="login"></ng-container>
        </ng-container>

        <!-- DEFAULT -->
        <ng-container *ngSwitchDefault>
          <h1>COMING SOON</h1>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</app-roam-layout-container-menu>


<ng-template #profile let-tab="tab">
  <app-account-profile-edit [tab]="tab" (onCancel)="actionDialog()"></app-account-profile-edit>
</ng-template>

<ng-template #login>
  <app-account-login (onCancel)="actionDialog()"></app-account-login>
</ng-template>
